.Spotify {
	text-align: center;
	background-color: #282c34;
}
.TopBar {
	display: flex;
	background-color: #282c34;
	flex-direction: row;
}
.imageGallery {
	width: 90%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}
.leftTopButton {
	margin-left: 80%;
	margin-right: 10px;
	background-color: black;
	color: white;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
}
.regularTopButton {
	margin-right: 10px;
	background-color: black;
	color: white;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
}
.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	color: white;
	justify-content: center;
}
.imageStack {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 10px;
	text-align: center;
}

.HeaderText {
	margin-top: 0%;
	font-size: calc(10px + 10vmin);
	align-items: center;
	justify-content: flex-start;
	text-decoration: underline;
}
.AboutBox {
	display: flex;
	width: 100%;
	justify-content: center;
}
.year2023 {
	color: #40f99b;
	font-size: 40px;
	align-items: center;
	justify-content: flex-start;
	text-decoration: underline;
}
.year2022 {
	color: #f42c04;
	font-size: 40px;
	align-items: center;
	justify-content: flex-start;
	text-decoration: underline;
}
.year2021 {
	color: #a2ad59;
	font-size: 40px;
	align-items: center;
	justify-content: flex-start;
	text-decoration: underline;
}
.PTitle {
	color: #40f99b;
	font-size: 20px;
	align-items: center;
	justify-content: flex-start;
	text-decoration: underline;
}
.introText {
	padding: 10px;
	max-width: 500px;
}

.PTitle3 {
	color: #a2ad59;
	font-size: 20px;
	align-items: center;
	justify-content: flex-start;
	text-decoration: underline;
}

.PTitle2 {
	color: #f42c04;
	font-size: 20px;
	align-items: center;
	justify-content: flex-start;
	text-decoration: underline;
}

.PTitle4 {
	color: #4c2f27;
	font-size: 20px;
	align-items: center;
	justify-content: flex-start;
	text-decoration: underline;
}

.year2024 {
	color: #4c2f27;
	font-size: 40px;
	align-items: center;
	justify-content: flex-start;
	text-decoration: underline;
}
