.Resume {
  text-align: center;
  background-color: #282c34;
}
.TopBar {
  display: flex;
  background-color: #282c34;
  flex-direction: row;
}
.leftTopButton {
  margin-left: 80%;
  margin-right: 10px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.regularTopButton {
  margin-right: 10px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}
.imageStack {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.Resumejpg {
  width: 800px;
  height: 1000px;
  justify-content: center;
  margin-top: 5px;
}

.LinkedIN {
  position: absolute;
  top: 48.3%;
  right: 23.4%;
  font-size: 14.2px;
  opacity: 0;
}
.Github {
  position: absolute;
  top: 51.5%;
  right: 27.9%;
  font-size: 14.2px;
  opacity: 0;
}

.HeaderText {
  margin-top: 0%;
  font-size: calc(10px + 10vmin);
  align-items: center;
  justify-content: flex-start;
  text-decoration: underline;
}
