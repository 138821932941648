.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.HeaderText {
  margin-top: 1%;
  font-size: calc(10px + 10vmin);
  align-items: center;
  justify-content: flex-start;
  text-decoration: underline;
  color: white;
}

.AboutTag {
  text-decoration: underline;
  margin-top: 1%;
  font-size: calc(10px + 6vmin);
  align-items: center;
  justify-content: flex-start;
}

.AboutLine {
  margin-top: 1%;
  font-size: calc(10px + 12vmin);
  align-items: center;
  justify-content: flex-start;
}
.AboutBox {
  display: flex;
  width: 100%;
  justify-content: center;
}
.introText {
  padding: 10px;
  max-width: 500px;
}

.grad {
  height: 653x;
  width: 490px;
  border-radius: 100px;
  margin: 10px;
}
.grad:hover {
  height: 653x;
  width: 490px;
  border-radius: 100px;
  margin: 10px;
  opacity: 0.9;
  z-index: 2;
}
.gradLanyard {
  height: 450x;
  width: 490px;
  border-radius: 100px;
  margin: 10px;
}
.gradLanyard:hover {
  height: 450x;
  width: 490px;
  border-radius: 100px;
  margin: 10px;
  opacity: 0.9;
}

.gradHor {
  height: 453x;
  width: 700px;
  border-radius: 100px;
  margin: 10px;
}
.gradHor:hover {
  height: 453x;
  width: 700px;
  border-radius: 100px;
  margin: 10px;
  opacity: 0.9;
}

.ImageGallery {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-self: center;
}
.ImageStack {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.TopBar {
  display: flex;
  background-color: #282c34;
  flex-direction: row;
}
.leftTopButton {
  margin-left: 80%;
  margin-right: 10px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.regularTopButton {
  margin-right: 10px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.Graduation {
  position: absolute;
  top: 80%;
  left: 27%;
  font-size: 30px;
  align-items: center;
  justify-content: flex-start;
  text-decoration: underline;
  color: white;
  z-index: 1;
}
